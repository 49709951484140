<template>
  <div class="relative">
    <shipblu-table
     :sst="true"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :max-items="maximumItems"
      pagination
      :data="myBluRefunds"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
        </div>
      </template>

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Request Date')}}</shipblu-th>
        <shipblu-th>{{$t('Requester Name')}}</shipblu-th>
        <shipblu-th>{{$t('Requester phone')}}</shipblu-th>
        <shipblu-th>{{$t('Transaction Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Payment Method')}}</shipblu-th>
        <shipblu-th>{{$t('Payment Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].tracking_number">
            {{data[indextr].tracking_number}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].refund_requested_at">
            {{ new Date(data[indextr].refund_requested_at).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customer_name">
            {{data[indextr].customer_name}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customer_phone">
            {{data[indextr].customer_phone}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].transaction_total_amount">
            {{data[indextr].transaction_total_amount}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].payment_method">
            {{data[indextr].payment_method ? data[indextr].payment_method : 'N/A'}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].transaction_paid_at">
            {{ new Date(data[indextr].transaction_paid_at).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].transaction_status">
            {{data[indextr].transaction_status === 'refund_requested' ? $t('Refund Requested') : data[indextr].transaction_status === 'refunded' ? $t('Approved') : $t('Rejected')}}
          </shipblu-td>

          <shipblu-td>
            <div class="flex gap-2">
              <vs-button class="bg-grey h-10 w-10 flex justify-center" @click="changeMyBluRefundStatus(data[indextr].id, 'refunded')">
                <span class="material-icons-outlined text-xl">check</span>
              </vs-button>
              <vs-button class="bg-red h-10 w-10 flex justify-center" @click="openRejectionModal(data[indextr].id)">
                <span class="material-icons-outlined text-xl">close</span>
              </vs-button>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>

    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="openModal"
      :title="$t('Select the rejection reasons')"
      :buttons-hidden="true">
      <div>
        <v-select v-validate="'required'" name="reason" :placeholder="$t('Select the rejection reasons')" :options="reasons" v-model="reason" label="name" class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="changeMyBluRefundStatus(refundId, 'refund_rejected', reason ? reason.name : '')" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import common from '../../../assets/utils/common'
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Refund Requested',
          value: 'refund_requested'
        },
        {
          name: 'Approved',
          value: 'refunded'
        },
        {
          name: 'Rejected',
          value: 'refund_rejected'
        }
      ],
      filters: [],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      myBluRefunds: [],
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      openModal: false,
      reasons: [
        { id: 1,
          name: 'Duplicate Request'
        },
        { id: 2,
          name: 'Order Delivered'
        },
        { id: 3,
          name: 'Order in Progress'
        },
        { id: 4,
          name: 'Previously Refunded'
        },
        { id: 5,
          name: 'Others...'
        }
      ],
      reason: '',
      filterValue: '',
      refundId: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push(
        {
          query: {
            page: this.currentPage,
            filter: this.filters
          }
        }
      ).catch(() => {})
      this.loadMybluRefunds()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadMybluRefunds()
      }
    }
  },
  methods: {
    openRejectionModal (id) {
      this.openModal = true
      this.$validator.resume()
      this.refundId = id
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMyBluRefundsSearch)
    },
    loadMyBluRefundsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMybluRefunds()
    },
    loadMybluRefunds () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&status=${this.filters}`
      sendRequest(false, false, this, `api/v1/payments/transactions/${query}`, 'get', null, true,
        (response) => {
          this.myBluRefunds = response.data
          this.searchInProgress = false
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
    },
    changeMyBluRefundStatus (id, status, reason) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const refundStatus = {
            refund_status: status,
            refund_notes: reason
          }
          sendRequest(false, false, this, `api/v1/payments/payment-requests/orders/${id}/refund-requests/`, 'patch', refundStatus, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale][status === 'refunded' ? 'Approved' : 'Rejected'],
                position: 'top-center'
              })
              this.closeModal()
              this.loadMybluRefunds()
            }
          )
        }
      })
    },
    changeFilter () {
      this.filters = [this.filterValue]
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMybluRefunds()
    },
    closeModal () {
      this.openModal = false
      this.$validator.pause()
      this.loadMybluRefunds()
      this.refundId = ''
      this.reason = ''
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    TableFilter,
    ShipbluPrompt,
    vSelect
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadMybluRefunds()
  }
}
</script>