<template>
  <div>
    <h2>{{ $t('Refund Requests') }}</h2>
    <div class="flex mb-6 sm:justify-start justify-center mt-4 border-0 border-b border-solid border-[#C7C7C7]">
      <div :class="$route.query.tab === tab.value ? 'text-primary bg-[#EDF4FB] border-0 border-b border-solid border-primary' : 'text-darkgray'" class="py-5 px-8 cursor-pointer" v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab.value)">
        <p class="font-semibold leading-tight">{{$t(tab.name)}}</p>
      </div>
    </div>
    <RefundRequestsTable v-if="activeTab === 0"/>
    <WalletWithdrawalsTable v-else/>
  </div>
</template>


<script>
import RefundRequestsTable from './components/RefundRequestsTable.vue'
import WalletWithdrawalsTable from './components/WalletWithdrawalsTable.vue'

export default {
  data () {
    return {
      tabs: [
        { 
          name: 'myBlu Refunds',
          value: 'myblu-refunds'
        },
        {
          name: 'Wallet withdrawals',
          value: 'wallet-withdrawals'
        }
      ],
      tabsDic: {'0': 'myblu-refunds', '1': 'wallet-withdrawals'},
      tabsNameDic: {'myblu-refunds': 0, 'wallet-withdrawals': 1},
      activeTab: 0
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    },
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
    },
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.$route.query.page ? Number(this.$route.query.page) : 1
        }
      }).catch(() => {})
    }
  },
  methods: {
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab
        }
      }).catch(() => {})
    }
  },
  components: {
    RefundRequestsTable,
    WalletWithdrawalsTable
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
  }
}
</script>