<template>
  <div class="relative">
    <shipblu-table
     :sst="true"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :max-items="maximumItems"
      pagination
      :data="refunds"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
        </div>
      </template>

      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant ID')}}</shipblu-th>
        <shipblu-th>{{$t('Withdraw Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Refunded Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="refunds.filter(item => item.status === 'refunded' || item.status === 'rejected').length !== refunds.length">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].merchant_name">
            {{data[indextr].merchant_name}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{data[indextr].merchant}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].requested_amount">
            {{data[indextr].requested_amount}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].refunded_amount">
            {{data[indextr].refunded_amount}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status">
            {{data[indextr].status === 'requested' ? $t('Refund Requested') : data[indextr].status === 'under_review' ? $t('Under Review') : data[indextr].status === 'refunded' ? $t('Approved') : $t('Rejected')}}
          </shipblu-td>

          <shipblu-td>
            <div v-if="data[indextr].status !== 'rejected' && data[indextr].status !== 'refunded'">
              <vs-button v-if="data[indextr].status !== 'under_review'" @click="changeRefundStatus(data[indextr].id, 'under_review')">{{ $t('Review') }}</vs-button>
              <div v-if="data[indextr].status === 'under_review'" class="flex gap-2">
                <vs-button class="bg-grey h-10 w-10 flex justify-center" @click="changeRefundStatus(data[indextr].id, 'refunded')">
                  <span class="material-icons-outlined text-xl">check</span>
                </vs-button>
                <vs-button class="bg-red h-10 w-10 flex justify-center" @click="openRejectionModal(data[indextr].id)">
                  <span class="material-icons-outlined text-xl">close</span>
                </vs-button>
              </div>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>

    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="openModal"
      :title="$t('Select the rejection reasons')"
      :buttons-hidden="true">
      <div>
        <v-select v-validate="'required'" name="reason" :placeholder="$t('Select the rejection reasons')" :options="reasons" v-model="reason" label="name" class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="changeRefundStatus(withdrawalsId, 'rejected', reason ? reason.name : '')"  class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import common from '../../../assets/utils/common'
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Refund Requested',
          value: 'requested'
        },
        {
          name: 'Under Review',
          value: 'under_review'
        },
        {
          name: 'Approved',
          value: 'refunded'
        },
        {
          name: 'Rejected',
          value: 'rejected'
        }
      ],
      filters: [],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      refunds: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      openModal: false,
      reasons: [
        { id: 1,
          name: 'Insufficient Balance'
        },
        { id: 2,
          name: 'Zero COD Wih On Hold Orders'
        },
        { id: 3,
          name: 'No Payout Method'
        },
        { id: 4,
          name: 'Wrong Bank Details'
        },
        { id: 5,
          name: 'Duplicate Request'
        },
        { id: 6,
          name: 'Others...'
        }
      ],
      filterValue: '',
      withdrawalsId: '',
      reason: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push(
        {
          query: {
            page: this.currentPage,
            filter: this.filters
          }
        }
      ).catch(() => {})
      this.loadRefundRequests()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadRefundRequests()
      }
    }
  },
  methods: {
    openRejectionModal (id) {
      this.openModal = true
      this.$validator.resume()
      this.withdrawalsId = id
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadRefundsSearch)
    },
    loadRefundsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadRefundRequests()
    },
    loadRefundRequests () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&status=${this.filters}`
      sendRequest(false, false, this, `api/v1/merchant-wallet-refunds/${query}`, 'get', null, true,
        (response) => {
          this.refunds = response.data.results
          this.totalRows = response.data.count
          this.searchInProgress = false
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
    },
    changeRefundStatus (id, status, reason) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const refundStatus = {
            status,
            comment: reason
          }
          sendRequest(false, false, this, `api/v1/merchant-wallet-refunds/${id}/`, 'patch', refundStatus, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale][status === 'under_review' ? 'Under Review' : status === 'refunded' ? 'Approved' : 'Rejected'],
                position: 'top-center'
              })
              this.closeModal()
              this.loadRefundRequests()
            }
          )
        }
      })
    },
    changeFilter () {
      this.filters = [this.filterValue]
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadRefundRequests()
    },
    closeModal () {
      this.openModal = false
      this.$validator.pause()
      this.loadRefundRequests()
      this.withdrawalsId = ''
      this.reason = ''
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    TableFilter,
    ShipbluPrompt,
    vSelect
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
  }
}
</script>